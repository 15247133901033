<template>
    <div>
                <!-- Begin of counter widgets -->
                <a-row  :gutter="24">
                    <a-col :span="24" :lg="12" :xl="6" class="mb-24">
                        <a-card :bordered="false" class="widget-1">
                            <a-statistic
                                title="Workflows"
                                :value="sums_data['workflow_count']"
                                :precision="0"
                                class="text-success"
                                />

                            <div  class="icon">
                                <v-icon name="scroll" scale="1.5" spin></v-icon>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :span="24" :lg="12" :xl="6" class="mb-24">
                        <a-card  :bordered="false" class="widget-1">
                            <a-statistic
                                title="Executions"
                                :value="sums_data['logs_count']"
                                :precision="0"
                                class="text-success"
                            />
                            <div class="icon">
                                <v-icon name="vote-yea" scale="1.5" spin></v-icon>
                            </div>
                        </a-card>
                    </a-col>
                    <a-col :span="24" :lg="12" :xl="6" class="mb-24">
                        <a-card  :bordered="false" class="widget-1">
                            <a-statistic
                                title="Issues"
                                :value="sums_data['logs_err_count']"
                                :precision="0"
                                class="text-success"
                            />
                            <div class="icon">
                                <v-icon name="exclamation-triangle" scale="1.5" spin></v-icon>
                            </div>
                        </a-card>
                    </a-col>

                    <a-col :span="24" :lg="12" :xl="6" class="mb-24">
                        <a-card  :bordered="false" class="widget-1">
                            <a-statistic
                                title="Running"
                                :value="sums_data['exec_sum']"
                                :precision="0"
                                class="text-success"
                            />
                            <div class="icon">
                                <v-icon name="rocket" scale="1.5" spin></v-icon>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <!-- End of counter widgets -->
                <!-- begin of chart -->
                <a-row :gutter="24" >
                    <a-col :span="16"  class="mb-24">
                        <a-card :bordered="false" class="dashboard-bar-line header-solid">
                            <template #title>
                                <a-icon type="line-chart" /> Workflow statistics 
                            </template>
                            <chart-line :height="310" v-if="renderComponentLine" :data="lineChartDataBarchat"></chart-line>
                             <a-empty v-else="renderComponentLine" :image="simpleImage" />
                        </a-card>
                    </a-col>
                    <a-col :span="8"  class="mb-24">
                        <a-card :bordered="false" class="dashboard-bar-line header-solid">
                            <template #title>
                                <a-icon type="line-chart" /> Workflows per namespace
                                <!-- <p>than last year <span class="text-success">+20%</span></p>	 -->
                            </template>
                            <template #extra>
                                <!-- <a-badge color="primary" class="badge-dot-primary" text="Traffic" />
                                <a-badge color="primary" class="badge-dot-secondary" text="Sales" /> -->
                            </template>
                            <chart-doughnut v-if="renderComponent" :height="310" :data="lineChartData"></chart-doughnut>
                            <a-empty v-else="renderComponent" :image="simpleImage" />
                        </a-card>
                    </a-col>
                </a-row>
                <!-- end of chart -->
                <a-row :gutter="24" type="flex">
                    <a-col :span="24">
                        <a-card size="small">
                            <a-table 
                                rowKey="id"
                                size="middle"
                                :showHeader="true"
                                :columns="columns"
                                :data-source="logs_data"
                                :loading="loading"
                                :pagination="true">
                                <span slot="name" slot-scope="text">
                                    <b>{{ text }}</b>
                                </span>

                                <span slot="app_name" slot-scope="text, record" v-if="text=='start'">
                                    <a-avatar :size="22" style="margin-right:3px;" :src="BaseURL+'/app/basic/start.png'" />
                                    <a-tag color="#1863d4">{{ text }} </a-tag>
                                </span>
                                <span slot="app_name" slot-scope="text, record" v-else-if="text=='Finish'">
                                    <a-avatar :size="22" style="margin-right:3px;" :src="BaseURL+'/app/basic/end.png'" />
                                    <a-tag color="#b92525">{{ text }} </a-tag>
                                </span>
                                <span slot="app_name" slot-scope="text, record" v-else-if="text=='Timer'">
                                    <a-avatar :size="22" style="margin-right:3px;" :src="BaseURL+'/app/basic/timer.png'" />
                                    <a-tag color="#3ca03b">{{ text }} </a-tag>
                                </span>
                                <span slot="app_name" slot-scope="text, record" v-else-if="text=='User input'">
                                    <a-avatar :size="22" style="margin-right:3px;" :src="BaseURL+'/app/basic/input.png'" />
                                    <a-tag color="#b628da">{{ text }} </a-tag>
                                </span>
                                <span slot="app_name" slot-scope="text, record" v-else-if="text=='WebHook'">
                                    <a-avatar :size="22" style="margin-right:3px;" :src="BaseURL+'/app/basic/webhook.png'" />
                                    <a-tag color="#53b0b5">{{ text }} </a-tag>
                                </span>
                                <span slot="app_name" slot-scope="text, record" v-else-if="text==''">
                                    <a-avatar :size="22" style="margin-right:3px;" :src="BaseURL+'/app/basic/unknown.png'" />
                                    <a-tag color="#53b0b5">{{ text }} </a-tag>
                                </span>
                                <span slot="app_name" slot-scope="text, record" v-else>
                                    <a-avatar :size="22" style="margin-right:3px;" :src="BaseURL+'/app/'+JSON.parse(record.args).app_dir+'/icon.png'" />
                                    <a-tag color="#7d838c">{{ text }} </a-tag>
                                </span>

                                <span slot="status" slot-scope="text">
                                    <a-tag v-if="text==0" color="#469823">Success</a-tag>
                                    <a-tag v-if="text==1" color="#9e8c0a">Warn</a-tag>
                                    <a-tag v-if="text==2" color="#9c5656">Error</a-tag>
                                    <a-tag v-if="text==3" color="#bf0c0c">Danger</a-tag>
                                </span>

                                <div slot="create_time" slot-scope="text">
                                    {{Dayjs(text).format('YYYY-MM-DD HH:mm:ss')}}
                                </div>
                            </a-table>
                        </a-card>
                    </a-col>
                </a-row>
                <br>
            <!-- </a-col> -->
        <!-- </a-row>   -->
	</div>
</template>
<script>
import {
    Area,
    Pie
} from '@antv/g2plot';

// Bar chart for "Active Users" card.
import ChartDoughnut from '../components/Charts/ChartDoughnut' ;
import ChartLine from '../components/Charts/ChartLine' ;
import { Empty } from 'ant-design-vue';


const columns = [{
        title: "Workflow",
        dataIndex: "name",
        key: "name",
        scopedSlots: {
            customRender: "name",
        },
        width: 220
    },
    {
        title: "Application",
        dataIndex: "app_name",
        key: "app_name",
        scopedSlots: {
            customRender: "app_name",
        },
        width: 160
    },
    {
        title: "Result",
        dataIndex: "result",
        key: "result",
        scopedSlots: {
            customRender: "result",
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        scopedSlots: {
            customRender: "status",
        },
        width: 60
    },
    {
        title: "Creation time",
        key: "create_time",
        dataIndex: "create_time",
        scopedSlots: {
            customRender: "create_time",
        },
        width: 190
    }
];

export default {
    components: {
            ChartDoughnut,
            ChartLine
		},
    name: 'dashboard',
    setup() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
        };
    },
    data() {
        return {
            sidebarCollapsed: false,
            // Main sidebar color.
            sidebarColor: "primary",
            // Main sidebar theme : light, white, dark.
            sidebarTheme: "dark",
            // Header fixed status.
            navbarFixed: false,
            sums_data: {},
            logs_data: [],
            workflow_data: {},
            login_history: [],
            columns,
            loading: false,
            exec_data: [],
            theme: "bright",
            curr_theme: "bright",
            autoit_json: {},
            piePlot: "",
            areaPlot: "",
            nick_name: "",
            baseURL: this.BaseURL,
            avatar: "",
            avatar1: "",
            avatar2: "",
            avatar3: "",
            avatar4: "",
            avatar5: "",
            avatar6: "",
            avatar7: "",
            avatar8: "",
            avatar9: "",
            execType1: "active",
            execType2: "",
            execType3: "",
            execType4: "",
            execType5: "",
            execType6: "",
            sy: {
                y: "0px"
            },
            loginstyle: "",
            lineChartData:{},
            lineChartDataBarchat: {},
            renderComponent: false,
            renderComponentLine: false
        }
    },
    computed: {
        collapsed: {
            get: function () {
                return this.$store.getters.getCollapsed;
            },
            set: function (val) {
                if (val) {
                    this.$store.commit("closeCollapsed");
                } else {
                    this.$store.commit("openCollapsed");
                }
            },
        },
    },
    mounted() {
        this.onLoad();

    },
    methods: {
        toggleSidebar( value ) {
				this.sidebarCollapsed = value ;
        },
        toggleNavbarPosition( value ) {
            this.navbarFixed = value ;
        },
        updateSidebarTheme( value ) {
            this.sidebarTheme = value ;
        },
        updateSidebarColor( value ) {
            this.sidebarColor = value ;
        },
        onLoad() {
            this.theme = this.$cookies.get("theme");
            this.onLoadSums();
            this.onLoadLogs();
            this.onLoadWorkflow();
            this.onLoadExec();
            this.getUserInfo();
            this.getTheme();
            this.getClientHeight();
            this.getFromTime();
        },
        getFromTime(time) {
            require('dayjs/locale/en')
            var relativeTime = require('dayjs/plugin/relativeTime');
            this.Dayjs.extend(relativeTime)
            var dayjs = this.Dayjs();
            return dayjs.locale('en').to(time);
        },
        getClientHeight() {
            let that = this;
            let clientHeight = document.documentElement.clientHeight;

            if (clientHeight >= 900) {
                that.sy = {
                    y: "calc(100vh - 610px)"
                }
                that.loginstyle = "height: calc(100vh - 265px);overflow-y: auto;"
            } else {
                that.sy = {
                    y: "300px"
                }
                that.loginstyle = "height: 645px;overflow-y: auto;"
            }

            window.onresize = function () {
                let clientHeight = document.documentElement.clientHeight;
                if (clientHeight >= 900) {
                    that.sy = {
                        y: "calc(100vh - 610px)"
                    }
                    that.loginstyle = "height: calc(100vh - 265px);overflow-y: auto;"
                } else {
                    that.sy = {
                        y: "300px"
                    }
                    that.loginstyle = "height: 645px;overflow-y: auto;"
                }
            }
        },
        getTheme() {
            var is_theme = this.$cookies.isKey("theme");
            if (is_theme) {
                var theme = this.$cookies.get("theme");
                document.getElementById("app").className = theme;
                this.curr_theme = theme;
                this.$store.commit("setTheme", this.curr_theme);
            } else {
                document.getElementById("app").className = "bright";
                this.curr_theme = "bright";
                this.$store.commit("setTheme", this.curr_theme);
            }
        },
        setTheme(name) {
            document.getElementById("app").className = name;
            this.$cookies.set("theme", name, -1);
            this.curr_theme = name;
            this.$store.commit("setTheme", this.curr_theme);
        },
        logout() {
            this.$cookies.remove("token");
            this.$cookies.remove("nick_name");
            this.$cookies.remove("account");
            this.$cookies.remove("user_id");

            window.location.href = "/";
        },
        getUserInfo() {
            this.$http
                .post("/api/v1/rest/get/user/info", {
                    id: this.$cookies.get("user_id")
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.avatar = res.data.avatar;
                        this.nick_name = res.data.nick_name;
                    }
                });

        },
        gd() {
            this.$router.push({
                name: "LogsHome"
            });
        },
        onLoadSums() {
            this.$http
                .post("/api/v1/rest/get/dashboard/sums")
                .then((res) => {
                    if (res.code == 0) {
                        this.sums_data = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onLoadLogs() {
            this.$http
                .post("/api/v1/rest/get/dashboard/logs")
                .then((res) => {
                    if (res.code == 0) {
                        this.logs_data = res.data;

                        // setTimeout(function () {
                        //     var t = document.querySelector(".ant-table-body");
                        //     t.scrollTop = t.scrollHeight;
                        // }, 100);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onLoadWorkflow() {
            this.$http
                .post("/api/v1/rest/get/dashboard/workflow")
                .then((res) => {
                    if (res.code == 0) {
                        this.workflow_data = res.data;
                        this.onLoadMain1();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onLoadExec(type = 1) {


            this.$http
                .post("/api/v1/rest/get/dashboard/barchart", {
                    type: type
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.exec_data = res.data;
                        this.onLoadMain2();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onLoadMain1() {
            this.lineChartData = this.workflow_data;    
            if (this.workflow_data.labels.length > 0) {
                this.renderComponent = true;
            }

        },
        onLoadMain2() {
            this.lineChartDataBarchat = this.exec_data;
            this.renderComponentLine = true
        
        }
    },
    watch: {
        '$store.getters.getTheme': function (e) {
            this.theme = this.$store.getters.getTheme;
            try {
                if (this.theme === "bright") {
                    this.piePlot.update({
                        label: {
                            style: {
                                fill: '#000',
                            }
                        }
                    });
                } else if (this.theme === "dark") {
                    this.piePlot.update({
                        label: {
                            style: {
                                fill: '#fff',
                            }
                        }
                    });
                }
            } catch (error) {
                
            }
        }
    }
}
</script>


